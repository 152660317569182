import React from 'react';
import { Home } from './views';

function App() {
    return (
        <Home />
    );
}

export default App;
